var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
        'events-card': true,
        'events-card--market': _vm.isMarket,
        'events-card--holiday': _vm.isHoliday,
        'events-card--approved': _vm.isMy,
        ['events-card--type--' + _vm.event.entityType]: true,
    },on:{"click":_vm.handleClick}},[(_vm.isHoliday || _vm.isMy || _vm.isMarket)?_c('div',{class:{
            'events-card__icon': true,
            'events-card__icon--holiday': _vm.isHoliday,
        }},[(_vm.isHoliday)?_c('Flag',{attrs:{"country-code":_vm.event.countryCode,"title":_vm.event.country}}):_vm._e(),(_vm.isMy || _vm.isMarket)?_c('EventTypeIcon',{attrs:{"type":_vm.event.type}}):_vm._e()],1):_vm._e(),(_vm.isHoliday)?_c('div',{staticClass:"events-card__text"},[_c('div',{staticClass:"events-card__ellipsis-overlay"},[_c('p',{staticClass:"events-card__name"},[_vm._v(" "+_vm._s(_vm._f("CapitalizeFirst")(_vm.event.name))+" ")])])]):_c('div',{staticClass:"events-card__text"},[_c('div',{staticClass:"events-card__ellipsis-overlay"},[_c('p',{staticClass:"events-card__country-name"},[_vm._v(" "+_vm._s(_vm._f("CapitalizeFirst")(_vm.event.name))+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }